import { Component, Inject, OnInit } from '@angular/core';
import { AuthService } from '@auth0/auth0-angular';
import { DOCUMENT } from '@angular/common';

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
	weekday = '';
	constructor(@Inject(DOCUMENT) public document: Document, public auth: AuthService) {}

	title = 'Launcher';
	playFrameSrc: string | null = null;
	playFrameOrientation: 'portrait' | 'landscape' = 'portrait';

	ngOnInit(): void {
		switch (new Date().getDay()) {
			case 0:
				this.weekday = 'sunday';
				break;
			case 1:
				this.weekday = 'monday';
				break;
			case 2:
				this.weekday = 'tuesday';
				break;
			case 3:
				this.weekday = 'wednesday';
				break;
			case 4:
				this.weekday = 'thursday';
				break;
			case 5:
				this.weekday = 'friday';
				break;
			case 6:
				this.weekday = 'sunday';
				break;
		}
	}

	onPlayFrameData({ playFrameSrc, playFrameOrientation }: { playFrameSrc: string; playFrameOrientation: 'portrait' | 'landscape' }) {
		this.playFrameSrc = playFrameSrc;
		this.playFrameOrientation = playFrameOrientation;
	}

	closePlayFrame() {
		this.playFrameSrc = null;
	}
}
