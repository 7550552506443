<form class="launch-form">
	<div fxLayout="column">
		<!-- Select Environment and Settings Button -->
		<div fxLayout="row" fxLayoutAlign="start start" fxLayoutGap="10px">
			<!-- Select Environment -->
			<mat-form-field fxFlex appearance="fill">
				<mat-label>Select Environment</mat-label>
				<mat-select (selectionChange)="fetchGames()" [(ngModel)]="selectedGTP" [ngModelOptions]="{ standalone: true }">
					<mat-option *ngFor="let GTP of GTPs" [value]="GTP">{{ GTP.displayName }}</mat-option>
				</mat-select>
			</mat-form-field>
			<!-- Settings Button -->
			<button class="settings-button" mat-icon-button fxFlex="none" (click)="openSettingsDialog()">
				<mat-icon>settings</mat-icon>
			</button>
		</div>

		<!-- Select Games and Info Button -->
		<div fxLayout="row" fxLayoutAlign="start start" fxLayoutGap="10px" *ngIf="selectedGTP">
			<!-- Select Environment -->
			<mat-form-field fxFlex appearance="fill">
				<mat-label>{{ games?.length ? 'Select Game' : 'No Installed Games' }}</mat-label>
				<mat-select [disabled]="!games" (selectionChange)="fetchPlayers()" [(ngModel)]="selectedGame" [ngModelOptions]="{ standalone: true }">
					<mat-option *ngFor="let game of games" [value]="game" [select]> {{ game.friendlyName }} </mat-option>
				</mat-select>
			</mat-form-field>
			<!-- Info Button -->
			<button class="info-button" mat-icon-button fxFlex="none" (click)="openInfoDialog()">
				<mat-icon>info</mat-icon>
			</button>
		</div>

		<!-- Select Player and Refresh -->
		<div fxLayout="row" fxLayoutAlign="start start" fxLayoutGap="10px" *ngIf="selectedGame">
			<mat-form-field fxFlex appearance="fill">
				<mat-label>Select Player</mat-label>
				<input type="text" #playerInput matInput [matAutocomplete]="auto" [formControl]="formControlPlayer" #autoTrigger="matAutocompleteTrigger" />
				<button mat-icon-button matSuffix *ngIf="formControlPlayer.value" (click)="clearPlayerInput(playerInput, autoTrigger)">
					<mat-icon>close</mat-icon>
				</button>
				<mat-autocomplete #auto="matAutocomplete" [displayWith]="displayWithPlayer" (optionSelected)="onPlayerSelected(playerInput)">
					<mat-option *ngFor="let player of filteredPlayerOptions | async" [value]="player">
						{{ player.loginName }}
					</mat-option>
				</mat-autocomplete>
			</mat-form-field>
			<!-- Refresh Button -->
			<button class="refresh-button" mat-icon-button fxFlex="none" (click)="refreshLaunchUrl()">
				<mat-icon>refresh</mat-icon>
			</button>
		</div>
	</div>

	<!-- Mobile Desktop Buttons -->
	<div fxLayout="column" fxLayoutAlign="center" fxLayoutGap="10px" fxFlexFill>
		<!-- <div fxLayout="row" fxLayoutAlign="center" fxLayoutGap="10px" fxFlexFill>
			<mat-button-toggle-group fxFlex name="fontStyle" aria-label="Font Style">
				<mat-button-toggle fxFlex disableRipple="{true}" #forcedPortraitToggle (click)="onOrientationToggleChange(forcedPortraitToggle, 'Portrait')" value="Portrait"
					>Mobile</mat-button-toggle
				>
				<div style="height: 100%; width: 10px; display: flex; justify-content: center; align-items: center">
					<div style="width: 1px; height: 65%; background-color: rgba(255, 255, 255, 0.15)"></div>
				</div>
				<mat-button-toggle fxFlex disableRipple="{true}" #forcedLandscapeToggle (click)="onOrientationToggleChange(forcedLandscapeToggle, 'Landscape')" value="Landscape"
					>Desktop</mat-button-toggle
				>
			</mat-button-toggle-group>
		</div> -->

		<!-- Launch Buttons -->
		<div [hidden]="selectedGame && selectedPlayer && launchUrl">
			<div fxLayout="row" fxLayoutAlign="center" fxLayoutGap="10px" fxFlexFill>
				<button [disabled]="!(selectedGame && quickLaunchUrl)" fxFlex mat-button class="action-buttons" mat-raised-button color="warn" (click)="launchMobileGameQuick()">
					<mat-icon [ngStyle]="!(selectedGame && quickLaunchUrl) ? { color: 'rgba(255, 255, 255, 0.3)' } : { color: 'black' }" class="icon-bolt" style="color: black"
						>bolt</mat-icon
					>
					Mobile
				</button>
				<button [disabled]="!(selectedGame && quickLaunchUrl)" fxFlex mat-button class="action-buttons" mat-raised-button color="warn" (click)="launchDesktopGameQuick()">
					<mat-icon [ngStyle]="!(selectedGame && quickLaunchUrl) ? { color: 'rgba(255, 255, 255, 0.3)' } : { color: 'black' }" [ngClass] class="icon-bolt" style="color: black"
						>bolt</mat-icon
					>
					Desktop
				</button>
			</div>
		</div>

		<div [hidden]="!(selectedGame && selectedPlayer && launchUrl)">
			<div fxLayout="row" fxLayoutAlign="center" fxLayoutGap="10px" fxFlexFill>
				<button fxFlex mat-button class="action-buttons" mat-raised-button color="warn" (click)="launchMobileGame()">
					<mat-icon class="icon-person" style="color: black">person</mat-icon>Mobile
				</button>
				<button fxFlex mat-button class="action-buttons" mat-raised-button color="warn" (click)="launchDesktopGame()">
					<mat-icon class="icon-person" style="color: black">person</mat-icon>Desktop
				</button>
			</div>
		</div>

		<div [hidden]="selectedGame && selectedPlayer && launchUrl">
			<div fxLayout="row" fxLayoutAlign="center" fxLayoutGap="10px" fxFlexFill>
				<button [disabled]="!(selectedGame && quickLaunchUrl)" fxFlex mat-button class="action-buttons" mat-raised-button color="warn" (click)="launchMobileGameQuickTab()">
					<mat-icon [ngStyle]="!(selectedGame && quickLaunchUrl) ? { color: 'rgba(255, 255, 255, 0.3)' } : { color: 'black' }" [ngClass] class="icon-tab" style="color: black"
						>launch</mat-icon
					>
					Mobile (tab)
				</button>
				<button [disabled]="!(selectedGame && quickLaunchUrl)" fxFlex mat-button class="action-buttons" mat-raised-button color="warn" (click)="launchDesktopGameQuickTab()">
					<mat-icon [ngStyle]="!(selectedGame && quickLaunchUrl) ? { color: 'rgba(255, 255, 255, 0.3)' } : { color: 'black' }" [ngClass] class="icon-tab" style="color: black"
						>launch</mat-icon
					>
					Desktop (tab)
				</button>
			</div>
		</div>

		<div [hidden]="selectedGame && selectedPlayer && launchUrl">
			<div fxLayout="row" fxLayoutAlign="center" fxLayoutGap="10px" fxFlexFill>
				<button [disabled]="!(selectedGame && quickLaunchUrl)" fxFlex mat-button class="action-buttons" mat-raised-button color="basic" (click)="copyQuickUrl()">Copy URL</button>
				<button [disabled]="!(selectedGame && quickLaunchUrl)" fxFlex mat-button class="action-buttons" mat-raised-button color="basic" (click)="createQuickLaunchQRCode()">
					QR-Code
				</button>
			</div>
		</div>

		<div [hidden]="!(selectedGame && selectedPlayer && launchUrl)">
			<div fxLayout="row" fxLayoutAlign="center" fxLayoutGap="10px" fxFlexFill>
				<button fxFlex mat-button class="action-buttons" mat-raised-button color="warn" (click)="launchMobileGameTab()">
					<mat-icon [ngStyle]="!(selectedGame && quickLaunchUrl) ? { color: 'rgba(255, 255, 255, 0.3)' } : { color: 'black' }" [ngClass] class="icon-tab" style="color: black"
						>launch</mat-icon
					>Mobile (tab)
				</button>
				<button fxFlex mat-button class="action-buttons" mat-raised-button color="warn" (click)="launchDesktopGameTab()">
					<mat-icon [ngStyle]="!(selectedGame && quickLaunchUrl) ? { color: 'rgba(255, 255, 255, 0.3)' } : { color: 'black' }" [ngClass] class="icon-tab" style="color: black"
						>launch</mat-icon
					>Desktop (tab)
				</button>
			</div>
		</div>

		<div [hidden]="!(selectedGame && selectedPlayer && launchUrl)">
			<div fxLayout="row" fxLayoutAlign="center" fxLayoutGap="10px" fxFlexFill>
				<button fxFlex mat-button class="action-buttons" mat-raised-button color="basic" (click)="copyUrl()">COPY URL</button>
				<button fxFlex mat-button class="action-buttons" mat-raised-button color="basic" (click)="createLaunchQRCode()">QR-CODE</button>
			</div>
		</div>

		<input
			type="text"
			id="copyQuickUrlInputField"
			matInput
			readonly
			style="opacity: 0; cursor: default; color: black; background-color: white; text-align: left"
			value="{{ quickLaunchUrl }}"
		/>
		<input
			type="text"
			id="copyUrlInputField"
			matInput
			readonly
			style="opacity: 0; cursor: default; color: black; background-color: white; text-align: left"
			value="{{ launchUrl }}"
		/>
	</div>
</form>
