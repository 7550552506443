import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
	selector: 'app-info-dialog',
	templateUrl: './info-dialog.component.html',
	styleUrls: ['./info-dialog.component.scss'],
})
export class InfoDialogComponent implements OnInit {
	displayedColumns: string[] = ['key', 'value'];
	dataSource: any = []; // Initialize dataSource as an empty array
	preferredOrder: string[] = [
		'friendlyName',
		'displayName',
		'gameId',
		'uploadDateFormatted',
		'version',
		'versions',
		'clientId',
		'moduleId',
		'clone',
		'rtp',
		'clientTypeId',
		'simpleName',
		'shortName',
		'packageName',
		'gameProvider',
		'gameProviderId',
		'freeGameSupport',
		'gamePath',
	];

	constructor(public dialogRef: MatDialogRef<InfoDialogComponent>, @Inject(MAT_DIALOG_DATA) public infoData: any) {}

	ngOnInit(): void {
		this.flattenData(this.infoData);
	}

	flattenData(data: any, parentKey: string = ''): void {
		// Sort keys so that preferred keys come first
		const keys = Object.keys(data).sort((a, b) => {
			const indexA = this.preferredOrder.indexOf(a);
			const indexB = this.preferredOrder.indexOf(b);
			if (indexA > -1 && indexB > -1) return indexA - indexB;
			if (indexA > -1) return -1;
			if (indexB > -1) return 1;
			return 0;
		});

		keys.forEach((key) => {
			const fullKey = parentKey ? `${parentKey}.${key}` : key;
			const value = data[key];

			if (typeof value === 'object' && !Array.isArray(value) && value !== null) {
				this.flattenData(value, fullKey);
			} else {
				this.dataSource.push({ key: fullKey, value: value, isArray: Array.isArray(value) });
			}
		});
	}

	onNoClick(): void {
		this.dialogRef.close();
	}
}
