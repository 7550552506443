import { Component } from '@angular/core';
import { AuthService } from '@auth0/auth0-angular';

@Component({
	selector: 'app-logout',
	templateUrl: './logout.component.html',
	styleUrls: ['./logout.component.scss'],
})
export class LogoutComponent {
	constructor(public auth: AuthService) {}

	logout(): void {
		this.auth.logout({ returnTo: window.location.origin });
	}
}
