<div class="content" [ngClass]="weekday" role="main">
	<div class="container" *ngIf="auth.isAuthenticated$ | async">
		<app-navigation></app-navigation>
		<h1>Launcher</h1>
		<app-launch-form (playFrameData)="onPlayFrameData($event)"></app-launch-form>
		<app-logout></app-logout>
	</div>
	<div class="container" *ngIf="!(auth.isAuthenticated$ | async)">
		<app-login></app-login>
	</div>
</div>

<router-outlet></router-outlet>

<app-play-frame
	*ngIf="playFrameSrc && playFrameOrientation"
	[playFrameSrc]="playFrameSrc"
	[playFrameOrientation]="playFrameOrientation"
	(closeOverlay)="closePlayFrame()"
></app-play-frame>
