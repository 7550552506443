<button mat-button [matMenuTriggerFor]="dev1Menu" matTooltip="GTP-622" matTooltipPosition="above">DEV 1</button>
<mat-menu #dev1Menu="matMenu" yPosition="below">
	<button mat-menu-item onclick="window.open('https://admin-app1-gtp622.installprogram.eu/','_blank')">Home</button>
	<button mat-menu-item onclick="window.open('https://admin-app1-gtp622.installprogram.eu/LaunchGame','_blank')">Launch</button>
	<button mat-menu-item onclick="window.open('https://admin-app1-gtp622.installprogram.eu/UserAccounts','_blank')">Accounts</button>
	<button mat-menu-item onclick="window.open('https://admin-app1-gtp622.installprogram.eu/TestData','_blank')">Testdata</button>
	<button mat-menu-item onclick="window.open('https://admin-app1-gtp622.installprogram.eu/Games','_blank')">Games</button>
	<button mat-menu-item onclick="window.open('https://admin-app1-gtp622.installprogram.eu/InstallClientContent','_blank')">Install Client</button>
</mat-menu>

<button mat-button [matMenuTriggerFor]="dev2Menu" matTooltip="GTP-640" matTooltipPosition="above">DEV 2</button>
<mat-menu #dev2Menu="matMenu" yPosition="below">
	<button mat-menu-item onclick="window.open('https://admin-app1-gtp640.installprogram.eu/','_blank')">Home</button>
	<button mat-menu-item onclick="window.open('https://admin-app1-gtp640.installprogram.eu/LaunchGame','_blank')">Launch</button>
	<button mat-menu-item onclick="window.open('https://admin-app1-gtp640.installprogram.eu/UserAccounts','_blank')">Accounts</button>
	<button mat-menu-item onclick="window.open('https://admin-app1-gtp640.installprogram.eu/TestData','_blank')">Testdata</button>
	<button mat-menu-item onclick="window.open('https://admin-app1-gtp640.installprogram.eu/Games','_blank')">Games</button>
	<button mat-menu-item onclick="window.open('https://admin-app1-gtp640.installprogram.eu/InstallClientContent','_blank')">Install Client</button>
</mat-menu>

<button mat-button [matMenuTriggerFor]="dev3Menu" matTooltip="GTP-547" matTooltipPosition="above">DEV 3</button>
<mat-menu #dev3Menu="matMenu" yPosition="below">
	<button mat-menu-item onclick="window.open('https://admin-app1-gtp547.installprogram.eu/','_blank')">Home</button>
	<button mat-menu-item onclick="window.open('https://admin-app1-gtp547.installprogram.eu/LaunchGame','_blank')">Launch</button>
	<button mat-menu-item onclick="window.open('https://admin-app1-gtp547.installprogram.eu/UserAccounts','_blank')">Accounts</button>
	<button mat-menu-item onclick="window.open('https://admin-app1-gtp547.installprogram.eu/TestData','_blank')">Testdata</button>
	<button mat-menu-item onclick="window.open('https://admin-app1-gtp547.installprogram.eu/Games','_blank')">Games</button>
	<button mat-menu-item onclick="window.open('https://admin-app1-gtp547.installprogram.eu/InstallClientContent','_blank')">Install Client</button>
</mat-menu>

<button mat-button [matMenuTriggerFor]="demoMenu" matTooltip="GTP-553" matTooltipPosition="above">DEMO</button>
<mat-menu #demoMenu="matMenu" yPosition="below">
	<button mat-menu-item onclick="window.open('https://admin-app1-gtp553.installprogram.eu/','_blank')">Home</button>
	<button mat-menu-item onclick="window.open('https://admin-app1-gtp553.installprogram.eu/LaunchGame','_blank')">Launch</button>
	<button mat-menu-item onclick="window.open('https://admin-app1-gtp553.installprogram.eu/UserAccounts','_blank')">Accounts</button>
	<button mat-menu-item onclick="window.open('https://admin-app1-gtp553.installprogram.eu/TestData','_blank')">Testdata</button>
	<button mat-menu-item onclick="window.open('https://admin-app1-gtp553.installprogram.eu/Games','_blank')">Games</button>
	<button mat-menu-item onclick="window.open('https://admin-app1-gtp553.installprogram.eu/InstallClientContent','_blank')">Install Game</button>
</mat-menu>

<button mat-button [matMenuTriggerFor]="qaMenu" matTooltip="GTP-554" matTooltipPosition="above">QA</button>
<mat-menu #qaMenu="matMenu" yPosition="below">
	<button mat-menu-item onclick="window.open('https://admin-app1-gtp554.installprogram.eu/','_blank')">Home</button>
	<button mat-menu-item onclick="window.open('https://admin-app1-gtp554.installprogram.eu/LaunchGame','_blank')">Launch</button>
	<button mat-menu-item onclick="window.open('https://admin-app1-gtp554.installprogram.eu/UserAccounts','_blank')">Accounts</button>
	<button mat-menu-item onclick="window.open('https://admin-app1-gtp554.installprogram.eu/TestData','_blank')">Testdata</button>
	<button mat-menu-item onclick="window.open('https://admin-app1-gtp554.installprogram.eu/Games','_blank')">Games</button>
	<button mat-menu-item onclick="window.open('https://admin-app1-gtp554.installprogram.eu/InstallClientContent','_blank')">Install Game</button>
</mat-menu>

<button mat-button [matMenuTriggerFor]="sandboxMenu" matTooltip="GTP-530" matTooltipPosition="above">SANDBOX</button>
<mat-menu #sandboxMenu="matMenu" yPosition="below">
	<button mat-menu-item onclick="window.open('https://admin-app1-gtp530.installprogram.eu/','_blank')">Home</button>
	<button mat-menu-item onclick="window.open('https://admin-app1-gtp530.installprogram.eu/LaunchGame','_blank')">Launch</button>
	<button mat-menu-item onclick="window.open('https://admin-app1-gtp530.installprogram.eu/UserAccounts','_blank')">Accounts</button>
	<button mat-menu-item onclick="window.open('https://admin-app1-gtp530.installprogram.eu/TestData','_blank')">Testdata</button>
	<button mat-menu-item onclick="window.open('https://admin-app1-gtp530.installprogram.eu/Games','_blank')">Games</button>
	<button mat-menu-item onclick="window.open('https://admin-app1-gtp530.installprogram.eu/InstallClientContent','_blank')">Install Game</button>
</mat-menu>

<button mat-button [matMenuTriggerFor]="releaseMenu" matTooltip="GTP-598" matTooltipPosition="above">RELEASE</button>
<mat-menu #releaseMenu="matMenu" yPosition="below">
	<button mat-menu-item onclick="window.open('https://admin-app1-gtp598.installprogram.eu/','_blank')">Home</button>
	<button mat-menu-item onclick="window.open('https://admin-app1-gtp598.installprogram.eu/LaunchGame','_blank')">Launch</button>
	<button mat-menu-item onclick="window.open('https://admin-app1-gtp598.installprogram.eu/UserAccounts','_blank')">Accounts</button>
	<button mat-menu-item onclick="window.open('https://admin-app1-gtp598.installprogram.eu/TestData','_blank')">Testdata</button>
	<button mat-menu-item onclick="window.open('https://admin-app1-gtp598.installprogram.eu/Games','_blank')">Games</button>
	<button mat-menu-item onclick="window.open('https://admin-app1-gtp598.installprogram.eu/InstallClientContent','_blank')">Install Client</button>
</mat-menu>
