import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

export interface Settings {
	hideTopBar: boolean;
	hideInGameLobby: boolean;
	catalyst: boolean;
}

@Component({
	selector: 'app-settings-dialog',
	templateUrl: './settings-dialog.component.html',
	styleUrls: ['./settings-dialog.component.scss'],
})
export class SettingsDialogComponent {
	constructor(public dialogRef: MatDialogRef<SettingsDialogComponent>, @Inject(MAT_DIALOG_DATA) public settings: Settings) {}

	onNoClick(): void {
		this.dialogRef.close();
	}
}
