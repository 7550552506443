import { Component } from '@angular/core';
import { AuthService } from '@auth0/auth0-angular';

@Component({
	selector: 'app-login',
	templateUrl: './login.component.html',
	styleUrls: ['./login.component.scss'],
})
export class LoginComponent {
	constructor(public auth: AuthService) {}

	loginWithRedirect(): void {
		this.auth.loginWithRedirect({
			connection: 'google-oauth2',
			prompt: 'select_account',
		});
	}
}
