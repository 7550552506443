import { Component, Inject, OnInit, ElementRef, HostListener, Output, EventEmitter } from '@angular/core';
import { Game, GTP, Player } from './launch-form.models';
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { MatButtonToggle } from '@angular/material/button-toggle';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Settings, SettingsDialogComponent } from '../settings-dialog/settings-dialog.component';
import { InfoDialogComponent } from '../info-dialog/info-dialog.component';

@Component({
	selector: 'app-launch-form',
	templateUrl: './launch-form.component.html',
	styleUrls: ['./launch-form.component.scss'],
})
export class LaunchFormComponent implements OnInit {
	@Output() playFrameData = new EventEmitter<{ playFrameSrc: string; playFrameOrientation: 'portrait' | 'landscape' }>();

	constructor(public dialog: MatDialog) {}

	SETTINGS_KEY = 'launcher-settings';

	selectedGTP: GTP;
	selectedGame: Game | null = null;
	selectedPlayer: Player | null = null;
	selectedQuickPlayer: string | null = null;

	formControlPlayer = new FormControl('');
	filteredPlayerOptions: Observable<Player[]>;

	settings: Settings = { hideTopBar: true, hideInGameLobby: true, catalyst: false };

	GTPs: GTP[] = [
		{ envName: '622', displayName: 'DEV 1' },
		{ envName: '640', displayName: 'DEV 2' },
		{ envName: '547', displayName: 'DEV 3' },
		{ envName: '553', displayName: 'DEMO' },
		{ envName: '554', displayName: 'QA' },
		{ envName: '530', displayName: 'SANDBOX' },
		{ envName: '598', displayName: 'RELEASE' },
	];
	games: Game[] | undefined = undefined;
	players: Player[] | undefined = undefined;
	launchUrl: string | null = null;
	quickLaunchUrl: string | null = null;

	private GTPApiKeys: { [key: string]: string };

	async ngOnInit(): Promise<void> {
		// Settings
		try {
			const settingsValue = localStorage.getItem(this.SETTINGS_KEY);
			if (settingsValue) {
				this.settings = JSON.parse(settingsValue);
			} else {
				localStorage.setItem(this.SETTINGS_KEY, JSON.stringify(this.settings));
			}
		} catch (error) {
			localStorage.setItem(this.SETTINGS_KEY, JSON.stringify(this.settings));
			console.error('Error in loading settings:', error);
		}

		this.GTPApiKeys = await this.getGTPApiKeys();

		// When player changes, set it to the selected player
		this.formControlPlayer.valueChanges.subscribe(async (selectedPlayer) => {
			this.selectedPlayer = null;
			if (selectedPlayer?.loginName === undefined) return;
			if (!this.players?.some((player) => player.loginName === selectedPlayer.loginName)) return;
			this.selectedPlayer = selectedPlayer;
			this.launchUrl = await this.fetchLaunchUrl();
		});
	}

	openSettingsDialog(): void {
		const dialogRef = this.dialog.open(SettingsDialogComponent, {
			data: this.settings,
		});

		dialogRef.afterClosed().subscribe(async () => {
			localStorage.setItem(this.SETTINGS_KEY, JSON.stringify(this.settings));
			if (this.quickLaunchUrl) {
				this.quickLaunchUrl = null;
				this.quickLaunchUrl = await this.fetchLaunchUrl();
			}
			if (this.launchUrl) {
				this.launchUrl = null;
				this.launchUrl = await this.fetchLaunchUrl();
			}
		});
	}

	openInfoDialog(): void {
		const dialogRef = this.dialog.open(InfoDialogComponent, {
			data: this.selectedGame,
		});
	}

	async refreshLaunchUrl() {
		this.launchUrl = null;
		this.launchUrl = await this.fetchLaunchUrl();
	}

	// Fetch Functions
	async fetchGames(): Promise<void> {
		this.games = undefined;
		this.selectedGame = null;
		this.selectedPlayer = null;

		this.formControlPlayer.reset();

		this.games = await this.getInstalledGameRecords()
			.then(async (data) => await data.json())
			.then((data) => {
				const retGames: Game[] = [];
				const games: Game[] = data.dataObject;
				for (const game of games) {
					if (game.displayName.toLowerCase().indexOf('desktop') !== -1) {
						continue;
					}
					const latestTitanVersion = game.versions
						?.filter((version) => version.titan)
						.reduce((prev, current) => {
							const prevDate = new Date(prev.uploadDateTime);
							const currentDate = new Date(current.uploadDateTime);
							return currentDate > prevDate ? current : prev;
						});

					if (latestTitanVersion) {
						game.version = latestTitanVersion.version;
						const date = new Date(latestTitanVersion.uploadDateTime);
						const day = String(date.getDate()).padStart(2, '0');
						const month = String(date.getMonth() + 1).padStart(2, '0');
						const year = date.getFullYear();
						const hours = String(date.getHours()).padStart(2, '0');
						const minutes = String(date.getMinutes()).padStart(2, '0');
						game.uploadDate = date;
						game.uploadDateFormatted = `${day}-${month}-${year} ${hours}:${minutes}`;
						retGames.push(game);
					}
				}
				retGames.sort((a, b) => b.uploadDate.getTime() - a.uploadDate.getTime());
				console.log(retGames);
				return retGames;
			})
			.catch((err) => {
				console.error(`Error In Fetching Games: ${err}`);
				return [];
			});

		if (this.games.length === 1) {
			this.selectedGame = this.games[0];
			this.fetchPlayers();
		}
	}

	setQuickLauncPlayer() {
		this.selectedQuickPlayer = 'p' + Math.floor(Math.random() * 99 + 1);
	}

	async fetchPlayers(): Promise<void> {
		this.players = undefined;
		this.selectedPlayer = null;

		this.setQuickLauncPlayer();
		this.quickLaunchUrl = await this.fetchLaunchUrl();

		this.formControlPlayer.reset();
		this.formControlPlayer.disable();

		this.players = await this.getUserAccounts()
			.then(async (data) => await data.json())
			.then((data) => {
				const pPlayers: Player[] = [];
				const otherPlayers: Player[] = [];

				data.dataObject.forEach((player: Player) => {
					const match = player.loginName.match(/^p(\d+)$/);
					if (match && parseInt(match[1]) >= 1 && parseInt(match[1]) <= 99) {
						pPlayers.push(player);
					} else {
						otherPlayers.push(player);
					}
				});

				pPlayers.sort((a, b) => {
					const aIndex = parseInt(a.loginName.slice(1));
					const bIndex = parseInt(b.loginName.slice(1));
					return aIndex - bIndex;
				});
				otherPlayers.sort();

				return [...pPlayers, ...otherPlayers];
			});

		const filterPlayers = (name: string): Player[] => {
			if (!this.players) return [];
			const filterValue = name.toLowerCase();
			return this.players.filter((player) => player.loginName.toLowerCase().includes(filterValue));
		};

		this.filteredPlayerOptions = this.formControlPlayer.valueChanges.pipe(
			startWith(''),
			map((inputValue) => {
				const name = typeof inputValue === 'string' ? inputValue : inputValue?.loginName;
				return name ? filterPlayers(name as string) : this.players ? this.players.slice() : [];
			})
		);

		this.formControlPlayer.enable();
	}

	async fetchLaunchUrl(): Promise<string> {
		return this.getGameUrl()
			.then((response) => response.json())
			.then((data) => {
				const url = data.dataObject;
				const urlObj = new URL(url);
				const params = urlObj.searchParams;
				params.delete('formFactor');
				const updatedUrl = urlObj.href;
				const customSettings = this.applyCustomSettings(updatedUrl);

				return `${updatedUrl}&customConfig=${customSettings}`;
			});
	}

	applyCustomSettings(url: string): string {
		let customConfig: any = {};

		// Top Bar
		if (this.settings.hideTopBar) {
			customConfig.plugins = {
				...customConfig.plugins,
				infobar: { enabled: false },
			};
		}

		// In Game Lobby
		if (this.settings.hideInGameLobby) {
			customConfig.plugins = {
				...customConfig.plugins,
				inGameLobby: { enabled: false },
			};
		}

		// Catalyst
		if (this.settings.catalyst) {
			url = `${url}&enableCatalystComms=true`;
			customConfig.plugins = {
				...customConfig.plugins,
				catalystComms: { enabled: true, enableRecording: true },
			};
		}

		return btoa(JSON.stringify(customConfig));
	}

	// Form Funcitons
	displayWithPlayer(user: Player): string {
		return user?.loginName ? user.loginName : '';
	}

	onPlayerSelected(input: HTMLInputElement) {
		input.blur();
	}

	clearPlayerInput(input: HTMLInputElement, autoTrigger: MatAutocompleteTrigger): void {
		this.launchUrl = null;
		this.selectedPlayer = null;
		this.formControlPlayer.setValue('');
		setTimeout(() => {
			input.blur();
			// 	autoTrigger.openPanel();
		});
	}

	applyFormFactor(url: string, formFactor: 'mobile' | 'desktop') {
		let urlObj = new URL(url);
		urlObj.searchParams.set('sbgFormFactor', formFactor);
		return urlObj.toString();
	}

	// Launch Functions
	async launchMobileGameQuick(): Promise<void> {
		if (this.quickLaunchUrl) {
			const playFrameSrc = `${this.applyFormFactor(this.quickLaunchUrl, 'mobile')}`;
			const playFrameOrientation = 'portrait';
			this.playFrameData.emit({ playFrameSrc, playFrameOrientation });
		}

		this.setQuickLauncPlayer();
		this.quickLaunchUrl = null;
		this.quickLaunchUrl = await this.fetchLaunchUrl();
	}

	async launchMobileGameQuickTab(): Promise<void> {
		if (this.quickLaunchUrl) {
			const mobileUrl = this.applyFormFactor(this.quickLaunchUrl, 'mobile');
			window.open(mobileUrl, '_blank');
		}

		this.setQuickLauncPlayer();
		this.quickLaunchUrl = null;
		this.quickLaunchUrl = await this.fetchLaunchUrl();
	}

	async launchDesktopGameQuick(): Promise<void> {
		if (this.quickLaunchUrl) {
			const playFrameSrc = `${this.applyFormFactor(this.quickLaunchUrl, 'desktop')}`;
			const playFrameOrientation = 'landscape';
			this.playFrameData.emit({ playFrameSrc, playFrameOrientation });
		}

		this.setQuickLauncPlayer();
		this.quickLaunchUrl = null;
		this.quickLaunchUrl = await this.fetchLaunchUrl();
	}

	async launchDesktopGameQuickTab(): Promise<void> {
		if (this.quickLaunchUrl) {
			const desktopUrl = this.applyFormFactor(this.quickLaunchUrl, 'desktop');
			window.open(desktopUrl, '_blank');
		}

		this.setQuickLauncPlayer();
		this.quickLaunchUrl = null;
		this.quickLaunchUrl = await this.fetchLaunchUrl();
	}

	async launchMobileGame(): Promise<void> {
		if (this.launchUrl) {
			const playFrameSrc = `${this.applyFormFactor(this.launchUrl, 'mobile')}`;
			const playFrameOrientation = 'portrait';
			this.playFrameData.emit({ playFrameSrc, playFrameOrientation });
		}
	}

	async launchMobileGameTab(): Promise<void> {
		if (this.launchUrl) {
			const mobileUrl = this.applyFormFactor(this.launchUrl, 'mobile');
			window.open(mobileUrl, '_blank');
		}
	}

	async launchDesktopGame(): Promise<void> {
		if (this.launchUrl) {
			const playFrameSrc = `${this.applyFormFactor(this.launchUrl, 'desktop')}`;
			const playFrameOrientation = 'landscape';
			this.playFrameData.emit({ playFrameSrc, playFrameOrientation });
		}
	}

	async launchDesktopGameTab(): Promise<void> {
		if (this.launchUrl) {
			const desktopUrl = this.applyFormFactor(this.launchUrl, 'desktop');
			window.open(desktopUrl, '_blank');
		}
	}

	async createQuickLaunchQRCode(): Promise<void> {
		if (this.quickLaunchUrl) {
			this.dialog.open(QrCodeDialog, {
				data: `${this.applyFormFactor(this.quickLaunchUrl, 'mobile')}`,
			});
		}

		this.setQuickLauncPlayer();
		this.quickLaunchUrl = null;
		this.quickLaunchUrl = await this.fetchLaunchUrl();
	}

	async createLaunchQRCode(): Promise<void> {
		this.dialog.open(QrCodeDialog, {
			data: `${this.launchUrl}`,
		});
	}

	async copyQuickUrl(): Promise<void> {
		const copyQuickUrlInputField = document.getElementById('copyQuickUrlInputField') as HTMLInputElement;
		copyQuickUrlInputField.select();
		copyQuickUrlInputField.setSelectionRange(0, 99999);
		document.execCommand('copy');
		this.setQuickLauncPlayer();
		this.quickLaunchUrl = null;
		this.quickLaunchUrl = await this.fetchLaunchUrl();
	}

	async copyUrl(): Promise<void> {
		const copyUrlInputField = document.getElementById('copyUrlInputField') as HTMLInputElement;
		copyUrlInputField.select();
		copyUrlInputField.setSelectionRange(0, 99999);
		document.execCommand('copy');
	}

	private async getGTPApiKeys() {
		return fetch('https://git.snowborngames.com/api/v4/projects/129/repository/files/keys.json/raw?ref=master', {
			mode: 'cors',
			headers: { 'Content-Type': 'application/json', 'PRIVATE-TOKEN': 'TYPsK1AgBXJp3uhh6Fmg' },
		})
			.then((response) => response.json())
			.then((data) => data);
	}

	private async getTitanVersion(): Promise<string> {
		return fetch(`https://axiomcore-app1-gtp${this.selectedGTP.envName}.installprogram.eu/MobileSettings/TitanVersions`, {
			mode: 'cors',
			headers: { 'Content-Type': 'application/json', 'x-api-key': this.GTPApiKeys[this.selectedGTP.envName] },
		})
			.then((response) => response.json())
			.then((data) => data.dataObject[data.dataObject.length - 1].appVersion);
	}

	private async getInstalledGameRecords(): Promise<Response> {
		return fetch(`https://axiomcore-app1-gtp${this.selectedGTP.envName}.installprogram.eu/Games/InstalledGameRecords`, {
			mode: 'cors',
			headers: { 'Content-Type': 'application/json', 'x-api-key': this.GTPApiKeys[this.selectedGTP.envName] },
		});
	}

	private async getUserAccounts() {
		return fetch(`https://axiomcore-app1-gtp${this.selectedGTP.envName}.installprogram.eu/UserAccounts`, {
			mode: 'cors',
			headers: { 'Content-Type': 'application/json', 'x-api-key': this.GTPApiKeys[this.selectedGTP.envName] },
		});
	}

	private async getGameUrl() {
		const titanVersion = await this.getTitanVersion();
		const fetchUrl = `https://axiomcore-app1-gtp${this.selectedGTP.envName}.installprogram.eu/Launch/GameUrl?
			Username=${this.selectedPlayer ? this.selectedPlayer?.loginName : this.selectedQuickPlayer}
			&Password=snow
			&LobbyName=DotCom
			&GameVersion=${this.selectedGame?.version}
			&LanguageCode=en
			&Iframe=false
			&Framework=titan
			&FrameworkVersion=${titanVersion}
			&ModuleId=${this.selectedGame?.moduleId}
			&ClientId=${this.selectedGame?.clientId}`;

		return fetch(fetchUrl, {
			mode: 'cors',
			headers: { 'Content-Type': 'application/json', 'x-api-key': this.GTPApiKeys[this.selectedGTP.envName] },
		});
	}
}

@Component({
	selector: 'qr-code-dialog',
	templateUrl: 'qr-code-dialog.html',
})
export class QrCodeDialog {
	constructor(@Inject(MAT_DIALOG_DATA) public qrCodeString: string) {}
	width = (Math.min(window.innerWidth, 600) / 3) * 2;
}
