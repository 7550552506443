import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
	selector: 'app-play-frame',
	templateUrl: './play-frame.component.html',
	styleUrls: ['./play-frame.component.scss'],
})
export class PlayFrameComponent {
	@Input() playFrameSrc: string | null = null;
	@Input() playFrameOrientation: 'portrait' | 'landscape' = 'portrait';
	@Output() closeOverlay = new EventEmitter<void>();

	onOverlayClick() {
		this.closeOverlay.emit();
	}
}
