<div class="scrollable-table-container" fxLayout="column" fxLayoutAlign="start stretch">
	<div *ngFor="let element of dataSource" [fxLayout]="isMobile ? 'column' : 'row'" class="table-row">
		<div fxFlex="45" class="table-cell key-cell">{{ element.key }}</div>
		<div fxFlex="45" class="table-cell value-cell">
			<ng-container *ngIf="!element.isArray; else select"> {{ element.value }} </ng-container>
			<ng-template #select>
				<mat-form-field appearance="fill">
					<mat-label>{{ element.key }}</mat-label>
					<mat-select>
						<mat-option *ngFor="let item of element.value" [value]="item">
							{{ item.version || item | json }}
						</mat-option>
					</mat-select>
				</mat-form-field>
			</ng-template>
		</div>
	</div>
</div>
