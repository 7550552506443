import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LaunchFormComponent, QrCodeDialog } from './launch-form/launch-form.component';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatOptionModule } from '@angular/material/core';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatSelectModule } from '@angular/material/select';
import { MatIconModule } from '@angular/material/icon';
import { QRCodeModule } from 'angularx-qrcode';
import { NavigationComponent } from './navigation/navigation.component';
import { MatMenuModule } from '@angular/material/menu';
import { AuthModule } from '@auth0/auth0-angular';
import { LoginComponent } from './login/login.component';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { LogoutComponent } from './logout/logout.component';
import { SettingsDialogComponent } from './settings-dialog/settings-dialog.component';
import { MatDialogModule } from '@angular/material/dialog';
import { InfoDialogComponent } from './info-dialog/info-dialog.component';
import { MatTableModule } from '@angular/material/table';
import { PlayFrameComponent } from './play-frame/play-frame.component';
import { SafeUrlPipe } from '../shared/pipes/safe-url-pipe';

@NgModule({
	declarations: [
		AppComponent,
		LaunchFormComponent,
		NavigationComponent,
		LoginComponent,
		QrCodeDialog,
		LogoutComponent,
		SettingsDialogComponent,
		InfoDialogComponent,
		PlayFrameComponent,
		SafeUrlPipe,
	],
	entryComponents: [QrCodeDialog, SettingsDialogComponent, InfoDialogComponent],
	imports: [
		BrowserModule,
		AppRoutingModule,
		FlexLayoutModule,
		FormsModule,
		BrowserAnimationsModule,
		ReactiveFormsModule,
		MatFormFieldModule,
		MatOptionModule,
		MatInputModule,
		MatButtonModule,
		MatSelectModule,
		MatAutocompleteModule,
		MatIconModule,
		QRCodeModule,
		MatMenuModule,
		MatButtonToggleModule,
		MatTooltipModule,
		MatDialogModule,
		MatCheckboxModule,
		MatTableModule,
		AuthModule.forRoot({
			domain: 'ronsku.eu.auth0.com',
			clientId: 'yHmIidcFU3xwegANXSN9iNjvO28bnZdF',
			cacheLocation: 'localstorage',
		}),
	],
	schemas: [CUSTOM_ELEMENTS_SCHEMA],
	providers: [],
	bootstrap: [AppComponent],
})
export class AppModule {}
